/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardBody, Card, NavItem, Nav, NavLink, CardFooter } from 'reactstrap';
import Scrollspy from 'react-scrollspy';
import { FcApproval, FcHighPriority, FcLowPriority, FcMediumPriority } from "react-icons/all";
import { FunnelChart } from "react-funnel-pipeline";
import 'react-funnel-pipeline/dist/index.css';
import vendasIMG from "../images/vendas.svg";
/**
 * Component
 */
class PageContentFortran extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            activeScrollSpy: false,
        };

        this.setActiveScrollspy = this.setActiveScrollspy.bind( this );
        this.getNavigationItem = this.getNavigationItem.bind( this );
    }

    onLinkClick( e ) {
        e.preventDefault();

        if ( e.currentTarget.hash ) {
            const $htmlBody = window.jQuery( 'html, body' );
            const hash = e.currentTarget.hash.split( '#' ).pop();
            const $target = window.jQuery( `#${ hash }` );

            if ( ! $target.length ) {
                return;
            }

            const $navbarTop = window.jQuery( '.rui-navbar-top' );

            if ( $navbarTop.length ) {
                $htmlBody.animate( {
                    scrollTop: $target.offset().top - $navbarTop.innerHeight(),
                }, 500 );
            } else {
                $htmlBody.animate( {
                    scrollTop: $target.offset().top,
                }, 500 );
            }
        }
    }

    setActiveScrollspy( item ) {
        if ( item && item.id ) {
            this.setState( {
                activeScrollSpy: `#${ item.id }`,
            } );
        }
    }

    getNavigationItem( data ) {
        const {
            activeScrollSpy,
        } = this.state;

        return (
            <li key={ `${ data.to }-${ data.title }` }>
                <Link
                    className={ classnames( 'nav-link', activeScrollSpy && activeScrollSpy === data.to ? 'active' : '' ) }
                    to={ data.to }
                    onClick={ this.onLinkClick }
                >
                    { data.title }
                </Link>
                { data.sub ? (
                    <ul className="nav flex-column">
                        { data.sub.map( ( innerData ) => (
                            this.getNavigationItem( innerData )
                        ) ) }
                    </ul>
                ) : '' }
            </li>
        );
    }

    render() {
        const {
            sideNav,
        } = this.props;

        const divProps = {
            ...this.props,
        };

        if ( divProps.sideNav ) {
            delete divProps.sideNav;
        }

        // page content.
        let content = (
            <Container fluid>
                <div { ...divProps } />
            </Container>
        );

        // add side navigation.
        if ( sideNav && sideNav.length ) {
            const items = sideNav.map( ( data ) => (
                data.to.replace( '#', '' )
            ) );

            content = (
                <Row className="align-items-start mr-0">
                    <Col xl={ 10 }>
                        { content }
                    </Col>
                    <Col xl={ 2 } className="d-none d-xl-block rui-scrollbar rui-page-sidebar-sticky">
                        <nav className="rui-page-sidebar">
                            <Scrollspy
                                items={ items }
                                className="nav"
                                currentClassName=""
                                onUpdate={ this.setActiveScrollspy }
                            >
                                { sideNav.map( ( data ) => (
                                    this.getNavigationItem( data )
                                ) ) }
                            </Scrollspy>
                        </nav>
                    </Col>
                </Row>
            );
        }

        return (
            <>
                <div className="rui-page-content pt-15">
                    <Row>
                        <Col xs={ 12 } sm={ 6 } md={ 2 } lg={ 2 } className="pr-0">
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <h3>Legenda</h3>
                                            <Nav className="flex-column mnt-3">
                                                <NavItem>
                                                    <NavLink
                                                        onClick={ () => this.toggleTab( 'activity' ) }
                                                        href="#"
                                                        className="p-0"
                                                    >
                                                        <FcLowPriority size={ 16 } />
                                                        <span className="ml-7">Prazo ideal</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        onClick={ () => this.toggleTab( 'activity' ) }
                                                        href="#"
                                                        className="p-0"
                                                    >
                                                        <FcMediumPriority size={ 16 } />
                                                        <span className="ml-7">Atenção</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        onClick={ () => this.toggleTab( 'activity' ) }
                                                        href="#"
                                                        className="p-0"
                                                    >
                                                        <FcHighPriority size={ 16 } />
                                                        <span className="ml-7">Urgente</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mt-20">
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <h3>Funil Ágil de vendas</h3>
                                            <FunnelChart
                                                heightRelativeToValue={ true }
                                                showNames={ false }
                                                chartHeight={ 140 }
                                                pallette={ [
                                                    '#F20574',
                                                    '#E88801',
                                                    '#0086E5',
                                                    '#F8E000',
                                                    '#00C689',
                                                    '#6bc905',
                                                ] }
                                                data={ [
                                                    { name: 'Mailing', value: 252 },
                                                    { name: 'Enviados & Leads', value: 105 },
                                                    { name: 'Viabilidade', value: 84 },
                                                    { name: 'Contato iniciado', value: 72 },
                                                    { name: 'Em negociação', value: 19 },
                                                    { name: 'Fechado', value: 10 },
                                                ] }
                                            />
                                        </CardBody>
                                        <CardFooter>
                                            <img src={ vendasIMG } alt="ágilCRM" />
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={ 12 } sm={ 6 } md={ 10 } lg={ 10 } className="pl-0">{ content }</Col>
                    </Row>
                </div>
                <footer className="rui-footer">
                    <Container fluid>
                        <p className="mb-0">2021 © Fortran Tecnologia. <FcApproval size={ 24 } /></p>
                    </Container>
                </footer>
            </>
        );
    }
}

export default PageContentFortran;
