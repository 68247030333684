/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
const jQuery = require( 'jquery' );

window.jQuery = jQuery;
window.$ = jQuery;

/*------------------------------------------------------------------

  RootUI Class

-------------------------------------------------------------------*/
class ROOTUI {}
window.RootUI = new ROOTUI();

window.UrlAPI = `https://apiagelcopel.negocieai.com.br/api/`;
window.UrlDB = `https://apiagelcopel.negocieai.com.br/db/`;
